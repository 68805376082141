import { Trans } from '@lingui/react'
import { Icon } from './Icon'

export type SlideinModalHeaderProps = {
  title: string
  subText?: string
  icon?: string
  onClose?: () => unknown
  className?: string
  titleClassName?: string
  withHorizontalMargin?: boolean
  withLeftPadding?: boolean
}

export function SlideinModalHeader(props: SlideinModalHeaderProps) {
  const {
    title,
    subText,
    icon,
    onClose,
    className,
    titleClassName,
    withHorizontalMargin,
    withLeftPadding,
  } = props
  return (
    <div
      className={`mx-5 flex flex-col gap-2 ${withLeftPadding ? 'mt-[10px]' : 'my-5 '} ${className}`}
    >
      <div className='sticky top-0 z-20 flex items-center justify-between text-60-grey'>
        <span
          className={`Type-XXL-Bold flex items-center gap-[10px] ${
            withLeftPadding ? 'pl-3' : ''
          } ${titleClassName}`}
        >
          {icon && <Icon name={icon} className='text-[32px]' />}
          <Trans id={title} />
          <span className='Type-XXL-Bold text-'>{subText}</span>
        </span>
        {onClose && (
          <button
            type='button'
            className='flex cursor-pointer items-center justify-center'
            onClick={() => onClose()}
          >
            <Icon name='close' className='text-[24px] text-tight-black' />
          </button>
        )}
      </div>
      {withHorizontalMargin && <hr className='text-20-grey' />}
    </div>
  )
}
