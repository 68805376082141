/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const DetachArtworkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DetachArtwork"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"artworkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"detachArtwork"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"artworkId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"artworkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userInputErrors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<DetachArtworkMutation, DetachArtworkMutationVariables>;
export type DetachArtworkMutationVariables = Types.Exact<{
  artworkId: Types.Scalars['ID']['input'];
}>;


export type DetachArtworkMutation = { detachArtwork?: { userInputErrors?: Array<{ code: string, message: string } | null> | null } | null };
