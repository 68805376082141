/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from '@emotion/styled'
import { ApolloError, useMutation } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material'

import axios from 'axios'

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import pdfIcon from '../../assets/images/PDF_icon.png'
import uploadCompleteIcon from '../../assets/images/upload-complete.svg'
import { DetachArtworkDocument } from '../Artwork/DetachArtwork.gql'
import { GenerateSignedCommandUrlDocument } from '../Artwork/GenerateSignedCommandUrl.gql'
import { StandardButton } from '../Button/ButtonStyles'
import { Icon } from '../Layout/Icon'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export type s3File = {
  url?: string
  fileName?: string
  size?: number
  uploadPercentage?: number
  artworkId?: string
  type?: string
}

type FileUploadModalProps = {
  isDiplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  setUploadedFile: (files: any, type?: string) => void
  modalId: string
  files: s3File[]
  type?: string
  setArtworkComment?: Dispatch<SetStateAction<string>>
  artworkComment?: string | null
  setProofOption?: Dispatch<SetStateAction<ProofOptionEnum>>
  proofOption?: ProofOptionEnum
  callback?: () => void
}

export enum ProofOptionEnum {
  print_asap = 'NO_PROOF_NEEDED',
  wait_for_proof = 'PROOF_NEEDED',
}

export function FileUploadModal(props: FileUploadModalProps) {
  const {
    isDiplayed,
    setIsDisplayed,
    setUploadedFile,
    modalId,
    files,
    type,
    setArtworkComment,
    artworkComment,
    proofOption,
    setProofOption,
    callback,
  } = props
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false)
  const [localFiles, setLocalFiles] = useState<Array<s3File>>(files)
  const [filesToDetach, setFilesToDetach] = useState<Array<s3File>>([])
  const [disableUpload, setDisableUpload] = useState<boolean>(localFiles?.length <= 0)
  const inputFilesRef = useRef(null)
  const [progress, setProgress] = useState<number>(0)
  const [error, setError] = useState<Error>()
  const handleClose = () => setIsDisplayed(false)
  const [detachArtwork] = useMutation(DetachArtworkDocument, {
    refetchQueries: ['CartPage', 'ShippingPage', 'PaymentPage'],
  })
  const [displayErrorPrompt, setDisplayErrorPrompt] = useState<boolean>(false)

  const [generateSignedCommandUrl] = useMutation(GenerateSignedCommandUrlDocument)

  const sendPOST = async (body: any, url: string) => {
    const rawResponse = await fetch(url, {
      method: 'PUT',
      body,
    }).catch((e: Error) => {
      setError(e)
      setIsUploadingFiles(false)
      setProgress(0)
    })

    await axios
      .request({
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'PUT',
        url,
        data: body,

        onUploadProgress: (p) => {
          const total = p?.total ?? p.bytes
          setProgress(Math.floor((p.loaded * 100) / total))
        },
      })
      .catch((e: Error) => {
        setError(e)
        setIsUploadingFiles(false)
        setProgress(0)
        return false
      })
    setProgress(0)
    return url?.substring(0, rawResponse?.url?.indexOf('?'))
  }

  const handleSetArtworkFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploadingFiles) return
    if (event?.target?.files && event?.target?.files[0]?.size > 20971520) {
      setDisplayErrorPrompt(true)
      return
    }
    setProgress(0)
    setIsUploadingFiles(true)
    const localUploadedFiles = [...localFiles]

    if (event?.target?.files) {
      const tempS3File = {
        url: '',
        fileName: event?.target?.files[0]?.name,
        size: event?.target?.files[0]?.size,
        uploadPercentage: progress,
      }
      localUploadedFiles.push(tempS3File)
      setLocalFiles(localUploadedFiles)

      const s3FileName = `artworks/${Date.now()}-${event?.target?.files[0]?.name}`
      const signedUrl = await generateSignedCommandUrl({
        variables: { objectKey: s3FileName, bucket: 'artwork' },
      }).catch((e: ApolloError) => {
        setError(e)
        setIsUploadingFiles(false)
        setProgress(0)
      })
      if (signedUrl) {
        const result = await sendPOST(
          event?.target?.files[0],
          signedUrl.data?.generateSignedCommandUrl?.url ?? '',
        )
        if (!result) {
          return
        }
        const newS3File = {
          url: result,
          fileName: event?.target?.files[0]?.name,
          size: event?.target?.files[0]?.size,
          uploadPercentage: 100,
        }
        localUploadedFiles.pop()
        localUploadedFiles.push(newS3File)
        setIsUploadingFiles(false)
        setLocalFiles(localUploadedFiles)
      }
    }
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    if (isUploadingFiles) return
    event.preventDefault()

    if (event?.dataTransfer?.files) {
      const filesArray = Array.from(event?.dataTransfer?.files)
      const localUploadedFiles = [...localFiles]
      for (let idx = 0; idx < filesArray.length; idx++) {
        setProgress(0)
        setIsUploadingFiles(true)
        const tempS3File = {
          url: '',
          fileName: filesArray[idx]?.name,
          size: filesArray[idx]?.size,
          uploadPercentage: progress,
        }
        localUploadedFiles.push(tempS3File)
        setLocalFiles(localUploadedFiles)
        const s3FileName = `artworks/${Date.now()}-${filesArray[idx].name}`
        // eslint-disable-next-line no-await-in-loop
        const signedUrl = await generateSignedCommandUrl({
          variables: { objectKey: s3FileName, bucket: 'artwork' },
        }).catch((e: ApolloError) => {
          setError(e)
          setIsUploadingFiles(false)
        })

        if (signedUrl) {
          // eslint-disable-next-line no-await-in-loop
          const result = await sendPOST(
            filesArray[idx],
            signedUrl.data?.generateSignedCommandUrl?.url ?? '',
          )
          if (!result) {
            return
          }
          const newS3File = {
            url: result,
            fileName: filesArray[idx].name,
            size: filesArray[idx]?.size,
            uploadPercentage: 100,
          }
          localUploadedFiles.pop()
          localUploadedFiles.push(newS3File)
          setLocalFiles(localUploadedFiles)

          if (idx === filesArray.length - 1) {
            setIsUploadingFiles(false)
          }
        }
      }
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleRemoveItem = (index: number) => {
    const localFilesCopy = [...localFiles]
    setLocalFiles(localFilesCopy.filter((item, idx) => idx !== index))

    const localFileToRemove = localFilesCopy.filter((item, idx) => idx === index)?.at(0)
    if (localFileToRemove?.artworkId) {
      // detachArtwork({
      //   variables: {
      //     artworkId: localFileToRemove.artworkId,
      //   },
      // }).catch((errorDetatch: Error) => {
      //   setError(errorDetatch)
      //   setLocalFiles(localFilesCopy)
      // })
      setFilesToDetach([...filesToDetach, localFileToRemove])
    }
  }

  const handleDetachFiles = async () => {
    // eslint-disable-next-line array-callback-return
    filesToDetach.map((localFileToRemove) => {
      if (localFileToRemove?.artworkId) {
        detachArtwork({
          variables: {
            artworkId: localFileToRemove.artworkId,
          },
        }).catch((errorDetatch: Error) => {
          setError(errorDetatch)
          return Promise.reject(errorDetatch)
        })
      }
    })
    return Promise.resolve('Success!')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setProofOption) {
      setProofOption(
        ProofOptionEnum.print_asap.toString() === (event.target as HTMLInputElement).value
          ? ProofOptionEnum.print_asap
          : ProofOptionEnum.wait_for_proof,
      )
    }
  }

  const handleScrollToTemplates = () => {
    handleClose()
    if (callback) {
      callback()
    }
  }

  useEffect(() => {
    setDisableUpload(localFiles?.length <= 0)
  }, [localFiles])

  useEffect(() => {
    setLocalFiles(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiplayed])

  return (
    <Modal className='z-10 overflow-y-auto' id={modalId} open={isDiplayed} onClose={handleClose}>
      <div className='absolute left-[5vw] top-[5vh] w-[90vw] rounded-md border-solid bg-pure-white md:left-[5vw] md:w-[90vw] xl:left-[calc((100vw-1150px)/2)] xl:max-w-[1150px]'>
        <div className='absolute right-0 cursor-pointer p-5'>
          <Icon
            name='cancel'
            className='align-middle text-[35px] font-bold text-tight-black hover:text-material-ui-blue'
            disabled={isUploadingFiles}
            onClick={async () => {
              if (isUploadingFiles) {
                return
              }
              setIsDisplayed(false)
              setUploadedFile(localFiles, type)
              setError(undefined)
              setLocalFiles([])
              await handleDetachFiles()
              setFilesToDetach([])
            }}
          />
        </div>
        <div className='m-[5vw] flex flex-col gap-x-[5%] gap-y-5 lg:grid lg:grid-cols-[50fr_40fr] xl:m-20 xl:gap-x-20'>
          <div
            className='flex flex-col items-center justify-start rounded-md border-[0.5px] border-solid border-black/30'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              boxShadow: '-3px 0px 6px 6px rgb(0 0 0 / 0.02)',
            }}
          >
            <span className='Type-XXL-Medium md:Type-XXXL-Medium w-full border-b-[0.5px] border-solid border-70-grey border-black/30 py-4 pl-4 md:pl-[64px]'>
              {i18n._('1. Upload your artwork')}
            </span>

            <label
              className={`mt-10 flex h-40 w-[90%] cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed border-material-ui-blue bg-material-ui-blue/10 px-4 transition hover:border-material-ui-dark-blue focus:outline-none ${
                isUploadingFiles ? 'bg-light-gray hover:border-medium-gray' : ''
              }`}
            >
              <div
                className={`${
                  isUploadingFiles ? 'hidden' : 'flex flex-col items-center justify-center'
                }`}
              >
                <Icon name='upload_file' className='align-middle text-3xl text-material-ui-blue' />
                <span className='mt-2 flex items-center space-x-2'>
                  <span className='text-gray-600 text-center font-medium'>
                    <span className='text-material-ui-blue underline'>
                      {i18n._('Click to upload')}
                    </span>
                    {i18n._(' or drag and drop multiple art files here.')}&nbsp;
                  </span>
                </span>
                <span className='Type-Base-Regular mt-5 text-70-grey'>
                  {i18n._('PDF, AI, SVG or JPG (max. 20MB)')}
                </span>
              </div>
              <div
                className={`${isUploadingFiles ? 'flex items-center justify-center' : 'hidden'}`}
              >
                <span>Uploading files. Please wait.</span>
              </div>
              <input
                type='file'
                name='file_upload'
                className='hidden'
                ref={inputFilesRef}
                onChange={handleSetArtworkFiles}
                onDrop={handleDrop}
                disabled={isUploadingFiles}
              />
            </label>
            {callback && (
              <span className='Type-XL-Regular mx-2 my-5 italic md:mx-0'>
                Looking for print templates?{' '}
                <span
                  className=' cursor-pointer text-material-ui-blue underline hover:text-material-ui-dark-blue'
                  onClick={handleScrollToTemplates}
                >
                  Click here.
                </span>
              </span>
            )}
            {localFiles.length > 0 && (
              <div className='mb-10 w-full'>
                <div className='mb-5 mt-5 flex max-h-[255px] w-full flex-col items-center justify-start overflow-y-auto'>
                  <div className='mt-2 flex w-full flex-col items-center justify-start gap-3 lg:mt-5'>
                    {localFiles.length > 0 &&
                      localFiles?.map((item, index) => (
                        <Paper
                          elevation={3}
                          key={item.url}
                          className='lg:Type-Large-Medium Type-Regular-Medium w-[90%] items-center justify-center border-[1px] border-medium-gray bg-light-gray  py-3
                          '
                          sx={{ bgcolor: '#e6e6e6', borderColor: '#bdbdbd' }}
                        >
                          <div className='flex w-full max-w-full items-center justify-between'>
                            <div className='flex items-center justify-between'>
                              {item.url && item.fileName ? (
                                <div className='hidden sm:block '>
                                  <Tooltip
                                    title={
                                      item?.fileName?.toLowerCase().endsWith('.pdf') ? (
                                        <Document
                                          className='w-auto border-[1px] border-black xs:h-[80px] sm:h-[100px] md:h-[150px]'
                                          file={item.url}
                                          onLoadError={console.error}
                                        >
                                          <Page
                                            height={150}
                                            pageNumber={1}
                                            className='max-h-[150px]'
                                          />
                                        </Document>
                                      ) : (
                                        <img
                                          src={item.url}
                                          alt={item.fileName}
                                          className='w-auto border-[1px] border-black xs:h-[80px] sm:h-[100px] md:h-[150px]'
                                        />
                                      )
                                    }
                                    placement='right-start'
                                  >
                                    {item?.fileName?.toLowerCase().endsWith('.pdf') ? (
                                      <img
                                        src={pdfIcon.src}
                                        alt={item.fileName}
                                        className='ml-5 h-[50px] w-auto  md:h-[80px]'
                                      />
                                    ) : (
                                      <img
                                        src={item.url}
                                        alt={item.fileName}
                                        className='ml-5 h-[50px] w-[50px] md:h-[80px] md:w-[80px]'
                                      />
                                    )}
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className='hidden md:block'>
                                  <Icon
                                    name='upload_file'
                                    className='ml-5 hidden align-middle text-3xl text-material-ui-blue'
                                  />
                                </div>
                              )}
                              <div className='flex flex-col items-start justify-center gap-1 sm:ml-0 md:ml-6'>
                                <Tooltip title={item.fileName}>
                                  <a
                                    href={item.url}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='Type-Large-Medium'
                                  >
                                    <span className='block max-w-[45vw] truncate whitespace-nowrap md:max-w-[30vw] lg:max-w-[12vw] xl:max-w-[12vw]'>
                                      {item.fileName}
                                    </span>
                                  </a>
                                </Tooltip>
                                {item?.size && (
                                  <span className='Type-Base-Regular text-70-grey'>
                                    {`${Math.floor((item.size ?? 0) / 1024 ** 1)}kb`}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='mr-5 flex items-center justify-between gap-3'>
                              <Icon
                                name='delete'
                                className={`cursor-pointer align-middle text-[30px] ${
                                  isUploadingFiles
                                    ? 'text-light-gray'
                                    : 'text-black hover:text-material-ui-blue'
                                }`}
                                onClick={() => {
                                  if (!isUploadingFiles) {
                                    handleRemoveItem(index)
                                  }
                                }}
                                disabled={isUploadingFiles}
                              />
                              {isUploadingFiles && (item.uploadPercentage ?? 0) < 100 && (
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                  <CircularProgress
                                    variant='determinate'
                                    value={progress}
                                    size={40}
                                  />
                                  <Box
                                    sx={{
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      position: 'absolute',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant='caption'
                                      component='div'
                                      color='black'
                                    >{`${Math.round(progress)}%`}</Typography>
                                  </Box>
                                </Box>
                              )}
                              {!item.artworkId && item.uploadPercentage === 100 && (
                                <Image
                                  src={uploadCompleteIcon}
                                  unoptimized
                                  height={10}
                                  className='ml-2'
                                />
                              )}
                            </div>
                          </div>
                        </Paper>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              boxShadow: '-3px 0px 6px 6px rgb(0 0 0 / 0.02)',
            }}
            className='flex flex-col items-start justify-start rounded-md border-[0.5px] border-solid border-black/30 shadow-xl md:items-start'
          >
            <span className='Type-XXL-Medium md:Type-XXXL-Medium w-full border-b-[0.5px] border-solid border-black/30 py-4 pl-[52px]'>
              {i18n._('2. Select a proofing option')}
            </span>
            <span className='Type-XL-Regular mt-4 px-5 text-[16px] text-black/80 md:px-[52px]'>
              {i18n._(
                'Every print-ready file gets an automated plus human review to ensure technical quality.',
              )}
            </span>
            <div className='mt-4 flex w-full flex-col items-center justify-center px-5 md:px-[52px]'>
              <FormControl>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='print_asap'
                  name='radio-buttons-group'
                  value={proofOption ?? ProofOptionEnum.wait_for_proof.toString()}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={ProofOptionEnum.wait_for_proof.toString()}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#26942A',
                          },
                        }}
                      />
                    }
                    sx={{ '.MuiFormControlLabel-label': { fontWeight: 'bold' }, height: '30px' }}
                    label={i18n._('Wait - send me a free digital proof')}
                    className='Type-XXL-Bold whitespace-nowrap'
                  />
                  {proofOption === ProofOptionEnum.wait_for_proof && (
                    <span className='Type-XL-Regular ml-8 text-[14px] text-70-grey'>
                      {i18n._(
                        `We will email you a link to your proof within 1 business day. Don't worry, we won't print your file until you check and approve it. Be aware this could delay your order by a day or more.`,
                      )}
                    </span>
                  )}
                  <FormControlLabel
                    value={ProofOptionEnum.print_asap.toString()}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#26942A',
                          },
                        }}
                      />
                    }
                    sx={{ '.MuiFormControlLabel-label': { fontWeight: 'bold' }, height: '30px' }}
                    label={i18n._('Print ASAP')}
                    className='Type-XXL-Bold mt-3'
                  />
                  {proofOption === ProofOptionEnum.print_asap && (
                    <span className='Type-XL-Regular ml-8 text-[14px] text-70-grey'>
                      {`If your file passes review, we will print it ASAP. If we find a problem we can't fix, we'll contact you.`}
                    </span>
                  )}
                </RadioGroup>
              </FormControl>
              <div className='m-2 flex w-full md:m-5'>
                <TextareaAutosize
                  placeholder={i18n._(
                    /* i18n */ 'Anything we should know about your ideal artwork layout, or any instructions? (optional)',
                  )}
                  defaultValue={artworkComment ?? ''}
                  minRows={3}
                  className='w-full rounded-[4px] border-black/30 bg-black bg-opacity-[0.02] text-[14px] hover:border-black'
                  onChange={(event) => {
                    if (setArtworkComment) {
                      setArtworkComment(event?.target?.value)
                    }
                  }}
                />
              </div>
            </div>

            <div className='my-6 flex w-full flex-col items-center justify-center gap-6 px-5 md:px-[52px]'>
              <StandardButton
                type='button'
                color={localFiles?.length > 0 ? 'primary' : 'info'}
                className=' w-full bg-material-ui-blue uppercase'
                disabled={disableUpload || isUploadingFiles}
                variant='contained'
                size='large'
                onClick={async () => {
                  setIsDisplayed(false)
                  setUploadedFile(localFiles, type)
                  setError(undefined)
                  setLocalFiles([])
                  await handleDetachFiles()
                  setFilesToDetach([])
                }}
              >
                {i18n._(/* i18n */ 'Proceed')}
              </StandardButton>
            </div>
          </div>
        </div>
        <ErrorPrompt error={error} />
        <AlertPrompt
          message={i18n._(
            'The file that you are trying to upload is greater than 20MB. Please resize your file and try again.',
          )}
          title='Upload Error'
          setIsDisplayed={setDisplayErrorPrompt}
          isDisplayed={displayErrorPrompt}
        />
      </div>
    </Modal>
  )
}
