import { ApolloError } from '@graphcommerce/graphql'
import { ErrorSnackbarProps } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Snackbar } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'
import { useEffect, useState } from 'react'
import { Icon } from '../Layout/Icon'

export type ErrorPromptProps = {
  error?: ApolloError | Error | undefined
} & Pick<ErrorSnackbarProps, 'action' | 'onClose'>

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction='left' />
}

export function ErrorPrompt(props: ErrorPromptProps) {
  const { error, action, ...passedProps } = props

  const [isDisplayed, setIsDisplayed] = useState<boolean>(true)

  useEffect(() => {
    setIsDisplayed(true)
  }, [error])

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setIsDisplayed(false)
  }

  if (!error) return null

  return (
    <Snackbar
      open={isDisplayed}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <div role='alert'>
        <div className='flex items-center justify-between rounded-t bg-red-500 px-4 py-2 font-bold text-pure-white'>
          <span>Error</span>
          <Icon
            name='disabled_by_default'
            className='mr-2 cursor-pointer align-middle text-xl lg:text-3xl'
            onClick={(e) => handleClose(e)}
          />
        </div>
        <div className='rounded-b border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red'>
          {!(error as any).graphQLErrors && !(error as any)?.networkError && !error?.message && (
            <p>{i18n._('Something went wrong. Please contact your system administrator.')}</p>
          )}
          {(error as any)?.graphQLErrors && (error as any)?.graphQLErrors?.length > 0 && (
            <p>{(error as any)?.graphQLErrors.map((e) => e.message).join(', ')}</p>
          )}
          {(error as any)?.networkError && (error as any)?.networkError?.message && (
            <p>Network Error: {(error as any).networkError.message}</p>
          )}
          {!(error as any)?.graphQLErrors &&
            !(error as any)?.networkError?.message &&
            error.message && <p>{error.message}</p>}
        </div>
      </div>
    </Snackbar>
  )
}
